<template>
    <div class="face-record">
        <div class="title">
            <div class="sub-title">面邀记录</div>
        </div>
        <el-alert
            title="温馨提示： 请自觉保护求职者个人信息隐私"
            type="warning"
            show-icon>
        </el-alert>
        <el-card class="box-card" style="min-height:100%">
            <el-form ref="dataFormRef" :model="dataForm" label-width="80px">
                <el-form-item label="面试时间">
                    <el-date-picker
                        v-model="search.beginTime"
                        type="date"
                        size="small"
                        value-format="yyyy-MM-dd"
                        @change="getDateTime"
                        placeholder="选择开始日期">
                    </el-date-picker> <span class="data-tip">至</span> 
                    <el-date-picker
                        v-model="search.endTime"
                        value-format="yyyy-MM-dd"
                        :disabled="!search.beginTime"
                        @change="getDateTime1"
                        :picker-options="pickerOptions"
                        type="date"
                        size="small"
                        placeholder="选择结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="面试结果">
                    <el-select v-model="search.status" placeholder="请选择面试反馈类型" size="small" @change="queryMyInterview" @clear="queryMyInterview" clearable>
                        <el-option label="待面试" :value="0"></el-option>
                        <el-option label="面试成功" :value="1"></el-option>
                        <el-option label="面试失败" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div v-if="state">
                <div class="record-list" v-if="list.length>0">
                    <div class="record-item" v-for="(item, index) in list" :key="`record${index}`">
                        <div class="item-detail flex">
                            <div class="avata">
                                <img :src="item.heardImg||require('../../../assets/img/company/base_logo.png')" alt="">
                            </div>
                            <div class="user-message">
                                <div class="strong">{{item.userName}}</div>
                                <div class="message">面试结果: {{item.status===0?'待面试':item.status===1?'已通过面试':'未通过面试'}}
                                </div>
                                <!-- <span class="area">女·16·程序员</span> -->
                            </div>
                            <div class="company-time">
                                <div class="data">面试时间：{{item.beginTime}}</div>
                                <div class="adress">面试地点：{{item.address}}</div>
                            </div>
                            <div class="creat-time">{{item.createTime.split(' ')[0]}}</div>
                            <div class="record-btns flex">
                                <el-button size="small"  @click="checkDetail(item, index)">邀请详情</el-button>
                                <el-button size="small" class="active" @click="openFeedback(item.id)" v-if="!item.status">面试反馈</el-button>
                            </div>
                        </div>
                        <div class="item-msg flex" v-if="item.flag">
                            【已发出面试邀请】{{item.message}}
                        </div>
                    </div>
                </div>
                <div class="no" v-else>
                    <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                    <p>正在加载中...</p>
                </div>
                <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
            </div>    
            <div class="no-data flex" v-else>
                <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                <p class="no-tips">亲爱的用户，目前还没有求职者投递相关岗位~~</p>
            </div> 
            <el-dialog
                title="面试反馈"
                :visible.sync="feedbackVisible"
                width="500px">
                <el-radio-group v-model="changeStatus.status">
                    <el-radio :label="1">通过面试</el-radio>
                    <el-radio :label="2">未通过面试</el-radio>
                </el-radio-group>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="feedbackVisible = false">取 消</el-button>
                    <el-button type="primary" @click="changeInterviewStatus">确 定</el-button>
                </span>
            </el-dialog>   
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
import AppPagination from '../../../components/pagination/AppPagination'
let beforeTime = null
export default {
    mounted() {
        this.queryMyInterview()
    },
    components: {
        AppPagination
    },
    data() {
        return {
            dataForm: {
                startTime: '',
                endTime: ''
            },
            feedbackVisible: false,
            state: true,
            list: [],
            radio:'',
            search: {
                pageNum: 1,
                pageSize: 10,
                beginTime: null,
                endTime: null,
                status: null, //0待面试 1面试成功 2面试失败
            },
            changeStatus: { // 反馈邀请的简历
                id: null,
                status: null 
            },
            total: 0
        }
    },
    computed: {
        pickerOptions() {
            let _this = this
            return {
                disabledDate(time) {
                    if (new Date(_this.search.beginTime).getTime() > 0) {
                        return new Date(time).getTime() < new Date(_this.search.beginTime).getTime()
                        
                    }
                    return false

                }
            }
        }
    },
    methods: {
        openFeedback(id) {
            this.changeStatus.id = id
            this.feedbackVisible = true
            // console.log('changeStatus', this.changeStatus)
        },
        //反馈面试简历
        changeInterviewStatus() {
            if (!this.changeStatus.status) {
                this.$message.warning('你先对简历做出反馈~')
                return
            }
            companyRequest.changeInterviewStatus(this.changeStatus).then((res) => {
                if (res.code === 200) {
                    this.$message.success('简历反馈成功~')
                    this.queryMyInterview()
                    this.feedbackVisible = false
                }
            }).catch((err) => {
                this.$message.error(err)
                this.feedbackVisible = false
            })
        },
        getDateTime() {
            // console.log('startTime', this.dataForm.startTime)
            // beforeTime = this.dataForm.startTime
            if (this.search.beginTime) {
                beforeTime = this.search.beginTime
                // console.log('beforeTime', beforeTime)
                this.search.endTime = null
            } else {
                beforeTime = null
                // console.log('beforeTime', beforeTime)
                
            }
            this.queryMyInterview()
        },
        getDateTime1() {
             this.queryMyInterview()
        },
        handleSize(size) {
            this.search.pageSize = size
            this.search.pageNum = 1
            this.queryMyInterview()
        },
        handleCurrent(num) {
            this.search.pageNum = num
            this.queryMyInterview()
        },
        checkDetail(item) {
            this.$set(item, 'flag', !item.flag)
            // item.flag = !item.flag
            // console.log('item', item)
            // console.log('flag', item.flag)
        },
        // 查询邀约列表
        queryMyInterview() {
            this.state = true
            let obj ={}
            obj = JSON.parse(JSON.stringify(this.search))
            if (obj.endTime) {
                obj.endTime = `${obj.endTime} 23:59:59`
            }
            console.log('obj', obj)
            companyRequest.queryMyInterview(obj).then(res => {
                if (res.code === 200) {
                    this.list = res.data.list
                    this.total = res.data.total
                    if (this.list.length === 0) {
                        this.state = false
                    } else {
                        for (let i = 0; i < this.list.length; i++) {
                            this.$set(this.list[i], 'flag', false)
                        }
                        // console.log('list', this.list)
                    }
                }
            }).catch(err => {
                // console.log(1)
                this.state = false
            })
        }
    },
    
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.face-record{
    height: 100%;
    .el-form{
        display: flex;
    }
    .no-data{
        margin: 150px 0 200px 0;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .el-alert{
        margin: 15px 0;
        font-size: 14px !important;
    }
    .data-tip{
        padding: 0 10px;
    }
    .record-list{
       .record-item{
           padding: 10px;
           justify-content: flex-start;
           border-bottom: 1px solid #eee;
           &:hover{
               .item-detail{
                   background: #f6f6f6;
               }
           }
         .item-detail{
            .avata{
                width: 80px;
                height: 80px;
                margin-right: 40px;
                overflow: hidden;
                border-radius: 50%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .user-message{
                width: 20%;
                margin-top: 15px;
                .strong{
                font-size: 16px;
                color: #111;
                letter-spacing: 0;
                margin-right: 8px;
                font-weight: 700;
                }
                .message{
                    margin-top: 20px;
                    color: #bbb;
                }
            }
            .company-time{
                width: 30%;
                div{
                    height: 46px;
                    line-height: 46px;
                    color: #111;
                    letter-spacing: 0;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 10px;
                }
            }
            .creat-time{
                width: 15%;
                align-self: center;
                color: #bbb;
            }
            .record-btns{
                width: 20%;
                align-self: center;
                justify-content: flex-end;
            }
         }
         .item-msg{
            padding: 10px 0;
            font-size: 13px;
            color: #999;
         }  
       } 
    }
}
</style>